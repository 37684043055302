import React, { useContext, useState } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import {
  Card,
  CardHeader,
  Col,
  Container,
  InputGroup,
  Row,
  Table,
} from "reactstrap";
import Datetime from "react-datetime";

import PageHeader from "../../../components/Headers/PageHeader.jsx";
import DownloadButton from "../../../components/Buttons/DownloadButton.jsx";
import UploadButton from "../../../components/Buttons/UploadButton.jsx";
import {
  getExcelDirectPayments,
  getExcelGuaranteedPayments,
  getExcelOnlyBrokerage,
  getExcelNormalPayments,
  getExcelWarrantyPayments,
  getInvoicingDetail,
  getExcelRefundPayments,
  getBankAccountsRegister,
  getOutflowsPayroll,
  getPayrollMX,
  getRegistrationFormMX,
  postBankAccountsRegister,
  postOutflowsPayroll,
  putPayrollMX,
  putRegistrationFormMX,
  postExcelPayments,
  getOutflowsPayrollJPM,
  getRefundPayrollJPM,
  postOutflowsPayrollJPM,
  getPayrollMXJPM,
  getRefundPayrollMXJPM,
  postOutflowsPayrollMXJPM,
  postWarrantyUploadPayroll
} from "../../../api";
import { AdminStateContext } from "../../../providers/AdminStateProvider.jsx";
import { useAuthToken } from "../../../hooks/AuthHook.jsx";

const Downloads = () => {
  const [adminState] = useContext(AdminStateContext);
  const [date, setDate] = useState(moment());
  const authToken = useAuthToken();
  const { country, legacyToken } = adminState;
  const downloads = {
    cl: [
      {
        downloadHandler: getExcelNormalPayments,
        uploadHandler: postExcelPayments,
        formDataKey: "payroll",
        name: "Nómina de pagos: Plan básico",
        filename: "pagos.basico",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getExcelGuaranteedPayments,
        uploadHandler: postExcelPayments,
        formDataKey: "payroll",
        name: "Nómina de pagos: Plan garantizado",
        filename: "pagos.premium",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getExcelWarrantyPayments,
        uploadHandler: postWarrantyUploadPayroll,
        formDataKey: "payroll",
        name: "Nómina de pagos: Garantías",
        filename: "pagos.garantias",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getExcelRefundPayments,
        uploadHandler: postExcelPayments,
        formDataKey: "payroll",
        name: "Nómina de pagos: Devoluciones",
        filename: "reembolsos.reembolsos",
        fileExtension: "xlsx",
        isV3: true,
      },
      {
        downloadHandler: getExcelDirectPayments,
        name: "Nómina de pagos: Transf. directas a la cuenta",
        filename: "pagos.directos",
        fileExtension: "xlsx",
        isV3: false,
      },
      {
        downloadHandler: getExcelOnlyBrokerage,
        name: "Nómina de pagos: Contratos solo corretaje",
        filename: "pagos.brokerage",
        fileExtension: "xlsx",
        isV3: false,
      },
    ],
    co: [
      {
        downloadHandler: getBankAccountsRegister,
        uploadHandler: postBankAccountsRegister,
        formDataKey: "accounts",
        name: "Registro TXT: Cuentas",
        filename: "cuentas_propietarios",
        fileExtension: "txt",
      },
      {
        downloadHandler: getOutflowsPayroll,
        uploadHandler: postOutflowsPayroll,
        formDataKey: "payments",
        name: "Descarga Nómina de pagos - Bancolombia",
        filename: "pagos_propietarios_bancolombia",
        fileExtension: "txt",
      },
      {
        downloadHandler: getOutflowsPayrollJPM,
        uploadHandler: postOutflowsPayrollJPM,
        formDataKey: "payroll",
        name: "Descarga Nómina de pagos - JPM",
        filename: "pagos_propietarios_jpm",
        fileExtension: "csv"
      },
      {
        downloadHandler: getRefundPayrollJPM,
        uploadHandler: postOutflowsPayrollJPM,
        formDataKey: "payroll",
        name: "Descarga Nómina de pagos devoluciones - JPM",
        filename: "pagos_devoluciones_jpm",
        fileExtension: "csv"
      },
      {
        downloadHandler: getInvoicingDetail,
        name: "Detalle facturación",
        filename: "invoicing_co",
        fileExtension: "xlsx",
      },
    ],
    mx: [
      {
        downloadHandler: getRegistrationFormMX,
        uploadHandler: putRegistrationFormMX,
        formDataKey: "accounts",
        name: "Registro TXT: Alta de cuentas - combinado",
        filename: "cuenta_propietarios",
        fileExtension: "txt",
      },
      {
        downloadHandler: getPayrollMX(true),
        uploadHandler: putPayrollMX(true),
        formDataKey: "payments",
        name: "Registro TXT: Alta de pagos a cuentas Santander",
        filename: "pagos_propietarios_santander",
        fileExtension: "txt",
      },
      {
        downloadHandler: getPayrollMX(false),
        uploadHandler: putPayrollMX(false),
        formDataKey: "payments",
        name: "Registro TXT: Alta de pagos a cuentas interbancarias",
        filename: "pagos_propietarios_interbancario",
        fileExtension: "txt",
      },
      {
        downloadHandler: getPayrollMXJPM,
        uploadHandler: postOutflowsPayrollMXJPM,
        formDataKey: "payroll",
        name: "Descarga Nómina de pagos - JPM",
        filename: "pagos_propietarios_jpm",
        fileExtension: "csv",
      },
      {
        downloadHandler: getRefundPayrollMXJPM,
        uploadHandler: postOutflowsPayrollMXJPM,
        formDataKey: "payroll",
        name: "Descarga Nómina de pagos devoluciones - JPM",
        filename: "pagos_devoluciones_jpm",
        fileExtension: "csv",
      }
    ],
  };

  const downloadFile = async (
    filename,
    fetchFunc,
    fileExtension,
    isV3 = false
  ) => {

    // Get token to use in the endpoints
    let token = legacyToken;
    if (isV3) {
      token = authToken;
    }

    // Parse parameters to use in the endpoint
    let response = await fetchFunc(token, {
      _from: date.format("YYYY-MM-DD"),
      _to: date.format("YYYY-MM-DD"),
    });

    // Logic to show the modal with the message.
    // Not all download functions return the responses.
    if (response && response.status && response.json) {
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Descarga exitosa."
        });
      }
      else {
        const json_response = await response.json();
        Swal.fire({
          icon: "error",
          title: "Error al descargar el archivo",
          text: JSON.stringify(json_response),
        });
      }
    }

    // This logic is just because the endpoint to download the file
    // can return sometimes the blob and sometimes the response.
    if (response.blob) {
      response = await response.blob();
    }

    // Create logic to download the file with the button
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([response]));
    const name = `${filename}.${date.format("DD-MM-YY")}.${fileExtension}`;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  // Create logic to upload
  const uploadFile = async (fileRef, formDataKey, uploadFunc) => {
    if (fileRef?.current?.files?.length) {
      const body = new FormData();
      body.append(formDataKey, fileRef.current.files[0]);

      const response = await uploadFunc(authToken, body, {
        _from: date.format("YYYY-MM-DD"),
        _to: date.format("YYYY-MM-DD"),
      });

      // Logic to show the modal with the message.
      // Not all upload functions return the responses.
      if (response && response.status && response.json) {
          const json_response = await response.json();

          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Carga exitosa",
              text: JSON.stringify(json_response),
            });
          }
          else {
            Swal.fire({
              icon: "error",
              title: "Error al cargar el archivo",
              text: JSON.stringify(json_response),
            });
          }
      }
    }
  };

  return (
    <>
      <PageHeader title={"Descargas"} />
      <Container>
        <Row className="justify-content-center">
          <Col xl={8}>
            <Card>
              <CardHeader className="border-0 m-auto">
                <Row className="align-items-center m-auto">
                  <span className="mr-4">Fecha</span>
                  <InputGroup className="input-group-alternative col">
                    <Datetime
                      value={date}
                      timeFormat={false}
                      onChange={setDate}
                      closeOnSelect
                      dateFormat="DD/MM/YYYY"
                    />
                  </InputGroup>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Item</th>
                    <th>Descargar</th>
                    <th>Cargar documento</th>
                  </tr>
                </thead>
                <tbody>
                  {downloads[country.toLowerCase()].map((dwnld) => (
                    <tr key={dwnld.filename}>
                      <td>{dwnld.name}</td>
                      <td className="table-actions text-center">
                        {dwnld.downloadHandler && (
                          <DownloadButton
                            onClick={(e) =>
                              downloadFile(
                                dwnld.filename,
                                dwnld.downloadHandler,
                                dwnld.fileExtension,
                                country.toLowerCase() !== "cl" || dwnld.isV3 // TODO: Enable auth0 token for all countries
                              )
                            }
                            text="Descargar"
                          />
                        )}
                      </td>
                      <td className="table-actions text-center">
                        {dwnld.uploadHandler && (
                          <UploadButton
                            onClick={(ref) =>
                              uploadFile(
                                ref,
                                dwnld.formDataKey,
                                dwnld.uploadHandler
                              )
                            }
                            text="Subir documento"
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Downloads;
